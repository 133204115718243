import React from 'react';

import './footer.scss';

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';

class Footer extends React.Component {
  render() {
    return (
      <>
        <footer className="contact">
          <main className="small">
            <div className="web">
              <h2>On The Web</h2>
              {/* <a href="http://twitter.com/miimsam/" aria-label="Twitter">
                <FontAwesomeIcon icon={['fab', 'twitter']} size="2x" />
              </a> */}
              <a href="https://www.linkedin.com/in/meysam-shahriari/" aria-label="Linkedin">
                <FontAwesomeIcon icon={['fab', 'linkedin-in']} size="2x" />
              </a>
              {/* <a href="https://miimsam.medium.com/" aria-label="Medium">
                <FontAwesomeIcon icon={['fab', 'medium']} size="2x" />
              </a> */}
              <a href="https://github.com/miimsam" aria-label="GitHub">
                <FontAwesomeIcon icon={['fab', 'github']} size="2x" />
              </a>
              <a href="mailto:hey@meysam.dev" aria-label="Email">
                <FontAwesomeIcon icon="envelope" size="2x" />
              </a>
            </div>
          </main>
        </footer>
        {/* <footer className="opensource">
          <main className="small">
            <p>
              This website is open source and Its code is available on{' '}
              <a href="https://github.com/miimsam/meysam.dev.gatsby">
                GitHub
                <FontAwesomeIcon icon={['fab', 'github']} />
              </a>
            </p>
          </main>
        </footer> */}
      </>
    );
  }
}

const footer = () => <Footer />;
export default footer;
